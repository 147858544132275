// BJB links and socil media links
// Later if needed we can get it based on the environment
export const ABOUT_URL = 'https://www.juliusbaer.com/en/about-us/';
export const CONTACT_URL = 'https://www.juliusbaer.com/en/contact/';
export const LEGAL_URL = 'https://www.juliusbaer.com/en/legal/';
export const PRIVACY_URL = 'https://www.juliusbaer.com/en/data-privacy-policy/';
export const TERMS_URL = 'https://www.juliusbaer.com/en/terms-of-use/';
export const ALERT_URL =
  'https://irpages2.eqs.com/websites/110555a/English/9000/information-service.html';
export const FACEBOOK_URL = 'https://www.facebook.com/bankjuliusbaer/';
export const TWITTER_URL = 'https://twitter.com/juliusbaer';
export const YOUTUBE_URL = 'https://www.youtube.com/channel/UCT-jQLkkW1Rc56PCNAqcKsw';
export const LINKEDIN_URL = 'https://www.linkedin.com/company/julius-baer/';
export const INSTAGRAM_URL = 'https://www.instagram.com/bankjuliusbaer/';
